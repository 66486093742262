import Menu from 'components/Menu';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { topDrawer } from 'reducer/slices/drawerSlice';
import { hideBalance, showBalance } from 'reducer/slices/settingSlice';
import utils from 'tools/utils';

const Header = ({ title }: any) => {
  const dispatch = useAppDispatch();
  // const [balanceShow, setBalanceShow] = useState(true);
  const useData = useAppSelector((state) => state.data);
  const useSetting = useAppSelector((state) => state.setting);

  const changeHide = async () => {
    if (useSetting.balance) {
      dispatch(hideBalance());
    } else {
      dispatch(showBalance());
    }
    Cookies.set('balanceShow', (!useSetting.balance).toString(), { expires: 365 });
  };

  return (
    <div className='topper'>
      <div className='topMenu'>
        <div className='topMenuLeft'>
          <h2 className='topTitle'>uWallet</h2>
          {/* <h3 className='topTitleDetail'>{title}</h3> */}
        </div>
        <div className={`eyeIcon ${useSetting.balance && 'eyeIconActive'}`} onClick={() => changeHide()} />
        <Link className='menu setting' to='/setting'></Link>
        <span className='menu' onClick={() => dispatch(topDrawer(<Menu />))}></span>
      </div>
      <div className='walletBox'>
        <Link className='parentTrsBox' to='/energy'>
          <div className='trsBox'>
            <div>
              <div className='trsText'>{utils.formatter(useData.data.available.EnergyLimit - useData.data.available.EnergyUsed)}</div>
              <div className='trsTextSmall'>Available Energy</div>
            </div>
            <div className='arrowIcon'></div>
          </div>
          <div className='trsBox'>
            <div>
              <div className='trsText'>{utils.formatter(useData.data.available.NetLimit - useData.data.available.NetUsed)}</div>
              <div className='trsTextSmall'>Available Bandwidth</div>
            </div>
            <div className='arrowIcon'></div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
