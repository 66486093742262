import Auto from 'components/Auto';
import Onex from 'components/Onex';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import { useAppDispatch } from 'reducer';
import { bottomDrawer } from 'reducer/slices/drawerSlice';

const Fast = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Header title={'Fast'} />
      <div className='splashContainer'>
        <br />
        <p className='infoAir'>Select your option:</p>
        <div className='fast1Data'>
          <div onClick={() => dispatch(bottomDrawer(<Onex />))}>Send & Receive 1XBet »</div>
          <div onClick={() => dispatch(bottomDrawer(<Auto type='pmVoucher' />))}>Create auto PM voucher »</div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Fast;
