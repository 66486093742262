import { createSlice } from '@reduxjs/toolkit';

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    balance: true,
  },
  reducers: {
    showBalance: (state) => {
      state.balance = true;
    },
    hideBalance: (state) => {
      state.balance = false;
    },
  },
});

export const { showBalance, hideBalance } = settingSlice.actions;
export default settingSlice.reducer;
