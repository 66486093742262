import config from 'config';
import { random } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

// const activeTabTitle = {
//   same: 'Same Amount',
//   different: 'Different Amount',
// }

type doneType = {
  code: string;
  amount: number;
  error?: string;
};

const CreateVoucher = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activeTabTitle = {
    same: t('sameAmount'),
    different: t('differentAmount'),
  };

  // const [voucher, setVoucher] = useState('');
  const [amount, setAmount] = useState<string[]>([]);
  const [isActive, setIsActive] = useState(activeTabTitle.same);
  const [done, setDone] = useState<doneType[]>([]);
  const [start, setStart] = useState(1);
  const [count, setCount] = useState(1);

  const whiler = isActive === activeTabTitle.same ? 1 : count;
  const realCount = amount.filter((e) => Number(e) > 0).length;
  const sum = amount.reduce((s, a) => s + Number(a), 0) * (isActive === activeTabTitle.same ? count : 1);

  const fee = data?.fee.send.type === 'percent' ? Number((Number(sum) * data?.fee.send.amount) / 100) || 0 : data?.fee.send.amount * realCount;
  let receive = Number(Number(sum) + fee) || 0;
  if (receive < 0) {
    receive = 0;
  }

  const copy = (voucher: string) => {
    if (voucher) {
      navigator.clipboard.writeText(voucher);
      dispatch(successAlert('Copied successful'));
    }
  };

  const copyAll = () => {
    let vouchers = '';
    done.forEach((e) => {
      if (!e.error) {
        vouchers += e.code + ' | ' + e.amount + '\n';
      }
    });

    if (vouchers) {
      navigator.clipboard.writeText(vouchers);
      dispatch(successAlert('Copied successful'));
    }
  };

  const setTab = (value: any) => {
    setCount(1);
    setAmount([]);
    setIsActive(value);
  };

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(activeTabTitle).forEach(([k, v]) => {
      rows.push(
        <span className={isActive === v ? 'inActiveTabs' : ''} onClick={() => setTab(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const changeAmount = (i: number, value: string) => {
    let newArr = [...amount];
    if (newArr[i] !== undefined) {
      newArr[i] = value || '';
    } else {
      newArr.push(value || '');
    }

    setAmount(newArr);
  };

  const tabOption = () => {
    const rows: any = [];

    for (let i = 0; i < 100; i++) {
      rows.push(
        <option key={i} value={i + 1}>
          {i + 1}
        </option>,
      );
    }

    return rows;
  };

  const doneList = () => {
    const rows: any = [];

    done.forEach((e, i) => {
      const code =
        e.code === '-' ? (
          <>In Processing...</>
        ) : data?.symbol === 'PM' || data?.symbol === 'PME' || data?.symbol === 'cVoucher' ? (
          <>
            Code: {e.code.split('-')[0]}
            <br />
            Number: {e.code.split('-')[1]}
          </>
        ) : (
          <>
            {t('code')}: {e.code}
          </>
        );

      rows.push(
        <div key={i} className='publicCopy historyItem createItem' onClick={() => copy(e.code)}>
          <span className='historyText'>{e.error ?? code}</span>
          <span className='historyText'>Amount: {e.amount}</span>
        </div>,
      );
    });

    return rows;
  };

  const listAmount = () => {
    const rows: any = [];

    for (let i = 0; i < whiler; i++) {
      rows.push(<input key={i} defaultValue={amount[i]} placeholder='Amount' onChange={(e) => changeAmount(i, e.target.value)} />);
    }

    return rows;
  };

  const createVoucher = async () => {
    const confirmAction = window.confirm(`You want to create ${sum} ${data?.symbol} voucher. Are you sure?`);
    if (confirmAction) {
      confirmVoucher();
    }
  };

  const confirmVoucher = async () => {
    if (sum < data?.limit?.send?.min || sum > data?.limit?.send?.max) {
      dispatch(errorAlert('Amount is out of range'));
      return;
    }

    setStart(2);

    const tag = random(10000000, 99999999);
    for (let i = 0; i < count; i++) {
      const amn = (isActive === activeTabTitle.same ? Number(amount[0]) : Number(amount[i])) || 0;
      if (amn > 0) {
        const result = await gather(`${config.apiUrl}/v1/voucher`, true).post({
          coin: data?.symbol,
          amount: amn,
          tag: tag.toString(),
        });

        if (result.code === 200) {
          setDone((prevState) => [{ code: result.data.code, amount: amn }, ...prevState]);
        } else {
          setDone((prevState) => [
            { code: '', error: 'Error: ' + (config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']), amount: amn },
            ...prevState,
          ]);
        }

        await new Promise((res) => setTimeout(res, 500));
      }
    }

    // const result = await gather(`${config.apiUrl}/v1/voucher`, true).post({
    //   coin: data?.symbol,
    //   amount: amount,
    // });

    // if (result.code === 200) {
    //   setVoucher(result.data.code);
    // } else {
    //   dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    // }

    // dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('createVoucher')}</div>
          <div className='receiveIcon receiveIconLarge createIcon'></div>
        </div>
        <div className='pageIcon'>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${data?.icon}`} className='popupImage iconImage' />
          <div className='pageIconText'>{data?.symbol}</div>
        </div>
        {start === 1 ? (
          <>
            <div className='spacerFlex'>
              <div className='tabs'>{isActiveTab()}</div>
              <div className='form'>
                <div className='inputParent'>
                  <span>{t('count')}</span>
                  <div className='selectBox'>
                    <select value={count} onChange={(e) => setCount(Number(e.target.value))}>
                      {tabOption()}
                    </select>
                  </div>
                </div>
                <div className='inputParent'>
                  <span>{t('amount')}</span>
                  {listAmount()}
                </div>
              </div>
              {/* <div className='form'>
                <input type='text' placeholder='Amount' onChange={(e) => setAmount(e.target.value)} />
              </div> */}

              <div className='limitData'>
                <span>
                  {t('minAmount')}: {data?.limit?.send?.min || 0}
                </span>
                <span>
                  {t('maxAmount')}: {data?.limit?.send?.max || 0}
                </span>
              </div>
              <div className='feeData'>
                <span>
                  {t('final')}: {utils.formatter(receive)}
                </span>
                <span>
                  {t('fee')}: {data?.fee.send.type === 'percent' ? (data?.fee.send.amount || 0) + '%' : data?.fee.send.amount || 0}
                </span>
              </div>
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => createVoucher()}>
              <i>{t('create')}</i>
            </div>
          </>
        ) : (
          <>
            <div className='spacerFlex'>
              <div className='publicCopy'>
                {t('pleaseWait')}. {t('generated')} {done.length}/{count}
              </div>
              <div style={{ maxHeight: '300px', overflow: 'scroll' }}>{doneList()}</div>
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => copyAll()}>
              <i>{t('copyAllVouchers')}</i>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateVoucher;
