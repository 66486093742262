import config from 'config';
import { useQRCode } from 'next-qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Auto = ({ type }: any) => {
  let name = '';
  let coin = '';
  let title = '';
  if (type === 'pmVoucher') {
    name = 'PM voucher';
    coin = 'USDT';
    title = 'Create PM voucher';
  }

  const dispatch = useAppDispatch();
  const [address, setAddress] = useState('FOR GET ADDRESS, SELECT A COIN');
  const { Image } = useQRCode();
  const { t } = useTranslation();

  const copy = () => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert('Copied successful'));
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/wallet/auto`, true).post({
      coin: coin,
      type: type,
    });

    if (result.code === 200) {
      setAddress(result.data.address);
    } else {
      dispatch(hideDrawer());
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Auto</div>
          <div className='receiveIcon receiveIconLarge'></div>
        </div>
        <div className='pageIcon'>
          <div className='pageIconText'>{title}</div>
        </div>
        <div className='spacerFlex'>
          <p className='infoAir' style={{ color: 'green' }}>
            If you send TRX or USDT to this address, the amount of transaction will auto converted to {name}
          </p>
          <br />
          <div className='publicCopy'>
            <span>{address}</span>
            <Image text={address} options={{ type: 'image/jpeg', quality: 1, margin: 2, width: 80 }} />
          </div>
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => copy()}>
          <i>{t('copyAddress')}</i>
        </div>
      </div>
    </div>
  );
};

export default Auto;
