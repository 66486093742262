import config from 'config';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Odds = () => {
  const from = moment().format('DD.MM.YYYY');
  const to = moment()
    .add(1, 'day')
    .format('DD.MM.YYYY');

  const dispatch = useAppDispatch();
  const useData = useAppSelector((state) => state.data);
  const [list, setList] = useState<any>([]);
  const [date, setDate] = useState<string>('Today');

  const currencyTitle: string[] = ['Today', 'Tomorrow'];

  const isActiveTab = () => {
    const rows: any = [];

    currencyTitle.forEach((v) => {
      rows.push(
        <span className={date === v ? 'inActiveTabs' : ''} onClick={() => setDate(v)} key={v}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const showlist = () => {
    let filter = list.filter((e: any) => e.data.some((j: any) => j.formatted_date === from));
    if (date === 'Tomorrow') {
      filter = list.filter((e: any) => e.data.some((j: any) => j.formatted_date === to));
    }
    console.log('filter', filter);

    const rows: any = [];

    filter.forEach((e: any, i: number) => {
      const inner: any = [];

      e.data.forEach((v: any, j: number) => {
        inner.push(
          <div key={900000 + j}>
            {v.localteam.name} - {v.visitorteam.name}
            <b>
              {v.localteam.goals} {v.status} {v.visitorteam.goals}
            </b>
          </div>,
        );
      });

      rows.push(
        <>
          <div key={i} className='oddsData' onClick={() => {}}>
            <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} />
            <h4>{e.name}</h4>
          </div>
          {inner}
        </>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/odd`, true).get();
    if (result.code === 200) {
      setList(result.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Header title='Odds' />

      <div className='oddsList'>
        <div className='tabs'>{isActiveTab()}</div>
        {showlist()}
      </div>
      <Bottom />
    </>
  );
};

export default Odds;
