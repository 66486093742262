import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from '../../tools/gather';

export const getProfile = createAsyncThunk('getProfile', async () => {
  return await gather(`${config.apiUrl}/v1/user`, true).get();
});

export const getWallet = createAsyncThunk('getWallet', async () => {
  return await gather(`${config.apiUrl}/v1/wallet`, true).get();
});

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    profile: {
      email: '',
      twoStep: {} as any,
      airdrop: {} as any,
      tasks: [] as any,
    },
    wallet: [] as any,
  },
  reducers: {
    updateWallet: (state, action) => {
      const index = state.wallet.findIndex((e: any) => e.coin === action.payload.coin);
      if (index >= 0) {
        state.wallet[index] = action.payload;
      } else {
        state.wallet.push(action.payload);
      }
    },
    updateAirdrop: (state, action) => {
      console.log(action.payload.newValue);
      state.profile.airdrop.current = action.payload.newValue as any;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload.data as any;
      })
      .addCase(getWallet.fulfilled, (state, action) => {
        state.wallet = action.payload.data as any;
      });
  },
});

export const { updateWallet, updateAirdrop } = loadingSlice.actions;
export default loadingSlice.reducer;
