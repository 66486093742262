import config from 'config';
import Cookies from 'js-cookie';
import { successAlert } from 'reducer/slices/alertSlice';
import { updateMarket } from 'reducer/slices/dataSlice';
import { getProfile, updateWallet } from 'reducer/slices/userSlice';
import { AppDispatch } from 'reducer/store';
import { Socket, io } from 'socket.io-client';

let socket: Socket;

const connect = async (dispatch: AppDispatch) => {
  if (!socket?.connected) {
    const accessToken = Cookies.get('accessToken');
    socket = io(config.apiUrl, {
      extraHeaders: {
        authorization: 'Bearer ' + accessToken,
      },
    });

    socket.on('connect', () => {
      console.log('socket', 'Connected');
    });

    socket.on('disconnect', () => {
      console.log('socket', 'Disconnected');
      setTimeout(() => {
        connect(dispatch);
      }, 1000);
    });

    socket.on('connect_failed', () => {
      console.log('socket', 'Failed');
    });

    socket.on('connect_error', (e) => {
      console.log('socket', 'Error: ' + e);
    });

    socket.on('wallet', (result: any) => {
      dispatch(updateWallet(result));
    });

    socket.on('ticker', (result: any) => {
      dispatch(updateMarket(result));
    });

    socket.on('score', (result: any) => {
      console.log('score');
      dispatch(getProfile());
      dispatch(successAlert('You get new PTS: ' + result.score));
    });

    // socket.on('airdrop', (result: any) => {
    //   dispatch(updateAirdrop(result));
    // });

    socket.on('transaction', (result: any) => {
      dispatch(successAlert('New transcation received: ' + result.receive + ' ' + result.coin));
    });

    socket.on('recheck', (result: any) => {
      dispatch(successAlert('Your transcation (' + result.type + ') has been completed: ' + result.receive + ' ' + result.coin));
    });
  }
};

export default {
  connect,
};
