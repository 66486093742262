import config from 'config';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Promo = () => {
  const dispatch = useAppDispatch();
  const [receiver, setReceiver] = useState('');
  const [receiver2, setReceiver2] = useState('');
  const [data, setData] = useState<any>(null);
  // const [data, setData] = useState({ username: 'x', password: 'z' });

  const activeTabTitle = {
    pullbet: 'Register in PullBet<br />500 PTS',
    invite: 'Invite  your friends<br />100 PTS',
  };

  const [isActive, setIsActive] = useState('pullbet');

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(activeTabTitle).forEach(([k, v]) => {
      rows.push(<span className={isActive === k ? 'inActiveTabs' : ''} onClick={() => setIsActive(k)} key={k} dangerouslySetInnerHTML={{ __html: v }}></span>);
    });

    return rows;
  };

  const send = async () => {
    if (receiver !== receiver2) {
      dispatch(errorAlert('Your email and repeat it are not same'));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/promo`, true).post({
      receiver: receiver.toLowerCase(),
      currency: 'irr',
      type: 'email',
    });

    if (result.code === 200) {
      setData(result.data);
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const copy = (voucher: string) => {
    navigator.clipboard.writeText(voucher);
    dispatch(successAlert('Copied successful'));
  };

  const [invites, setInvites] = useState([]);

  const listFriends = () => {
    const rows: any = [];

    invites?.forEach((e: any, i: number) => {
      rows.push(
        <i key={i}>
          {i}. {e}
        </i>,
      );
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any friend</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/user/friends`, true).get();
    if (result.code === 200) {
      setInvites(result.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    // init();
  }, []);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>PullBet</div>
          <div className='receiveIcon bonusIcon receiveIconLarge'></div>
        </div>
        {/* <div className='tabs'>{isActiveTab()}</div> */}
        <br />
        {isActive !== 'pullbet' ? (
          !telegram ? (
            <p className='infoAir' style={{ color: 'red' }}>
              You must use uWallet mini app on Telegram
            </p>
          ) : (
            <div className='spacerFlex airFlex'>
              <p className='infoAir'>
                Share your invite link with your friends
                <br />
                and get more bonus!
                <div onClick={() => copy(`https://t.me/uwalletbiz_bot/uwallet?startapp=${webApp?.user?.id}`)}>
                  https://t.me/uwalletbiz_bot/uwallet?startapp={webApp?.user?.id}
                </div>
              </p>
              {/* <div className='yourFriends'>{listFriends()}</div> */}
            </div>
          )
        ) : (
          <>
            <div className='direct' style={{ flex: 'none' }}>
              <i>Fast Register In Pullbet.com</i>
            </div>
            {data ? (
              <div className='spacerFlex'>
                <div className='usernamePB' onClick={() => copy(data.username)}>
                  Username: {data.username}
                  <b className='copy'></b>
                </div>
                <div className='usernamePB' onClick={() => copy(data.password)}>
                  Password: {data.password}
                  <b className='copy'></b>
                </div>
              </div>
            ) : (
              <>
                <div className='spacerFlex'>
                  <div className='form'>
                    <div className='inputParent'>
                      <span className='selectAddress'>Enter your email</span>
                      <input type='text' placeholder={'Type your email here'} onChange={(e) => setReceiver(e.target.value)} />
                    </div>
                    <div className='inputParent'>
                      <span className='selectAddress'>Enter your email again</span>
                      <input type='text' placeholder={'Type your email here again'} onChange={(e) => setReceiver2(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => send()}>
                  <i>Request Code</i>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Promo;
