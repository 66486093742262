import { configureStore } from '@reduxjs/toolkit';
import alertSlice from './slices/alertSlice';
import dataSlice from './slices/dataSlice';
import drawerSlice from './slices/drawerSlice';
import loadingSlice from './slices/loadingSlice';
import settingSlice from './slices/settingSlice';
import userSlice from './slices/userSlice';

const store = configureStore({
  reducer: {
    loading: loadingSlice,
    alert: alertSlice,
    data: dataSlice,
    drawer: drawerSlice,
    user: userSlice,
    setting: settingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
